'use strict'

// Import global styles
require('normalize.css')
require('./static/fonts.css')
require('swiper/swiper.scss')
require('swiper/components/pagination/pagination.scss')
require('swiper/components/navigation/navigation.scss')
require('swiper/components/effect-fade/effect-fade.scss')

// A stub function is needed because gatsby won't load this file otherwise
// (https://github.com/gatsbyjs/gatsby/issues/6759)
exports.onClientEntry = () => {}
